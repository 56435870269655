@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
   url("./OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"),
   url("./OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
}
/* 
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
   url("./Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
   url("./Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
   url("./Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
} */
